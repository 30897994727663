<template>
  <nav class="w-full flex items-center justify-center bg-white border-gray-200">
    <div
      class="w-full max-w-7xl flex flex-wrap items-center justify-between mx-auto menuSetting px-6 py-3"
    >
      <router-link to="/">
        <img
          loading="lazy"
          src="../assets/logo_curaduria.webp"
          width="120"
          alt="Logo-curaduria"
        />
      </router-link>
      <div class="flex md:order-2">
        <a
          href="https://sistema.curaduria2yopal.com/login"
          target="_blank"
          rel="noreferrer noopener"
          class="text-white bg-[#2C8930] hover:bg-[#34a03a] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-2.5 text-center mr-3 md:mr-0 hidden md:flex"
        >
          Iniciar sesión
        </a>
        <a
          href="https://sistema.curadia2yopal.com"
          target="_blank"
          rel="noreferrer noopener"
          class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        >
          <span class="sr-only">Iniciar sesión</span>
          <svg
            class="w-8 h-8"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="24" height="24" fill="white" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM11.9999 6C9.79077 6 7.99991 7.79086 7.99991 10C7.99991 12.2091 9.79077 14 11.9999 14C14.209 14 15.9999 12.2091 15.9999 10C15.9999 7.79086 14.209 6 11.9999 6ZM17.1115 15.9974C17.8693 16.4854 17.8323 17.5491 17.1422 18.1288C15.7517 19.2966 13.9581 20 12.0001 20C10.0551 20 8.27215 19.3059 6.88556 18.1518C6.18931 17.5723 6.15242 16.5032 6.91351 16.012C7.15044 15.8591 7.40846 15.7251 7.68849 15.6097C8.81516 15.1452 10.2542 15 12 15C13.7546 15 15.2018 15.1359 16.3314 15.5954C16.6136 15.7102 16.8734 15.8441 17.1115 15.9974Z"
              fill="#2C8930"
            />
          </svg>
        </a>
        <button
          data-collapse-toggle="navbar-sticky"
          type="button"
          class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          aria-controls="navbar-sticky"
          aria-expanded="false"
          @click="actionsMenu()"
        >
          <span class="sr-only">Open main menu</span>
          <svg
            class="w-8 h-8"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
      <el-menu
        :default-active="activeIndex"
        class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
        mode="horizontal"
      >
        <!-- @select="handleSelect" -->
        <router-link to="/">
          <el-menu-item index="1"> Inicio </el-menu-item>
        </router-link>
        <router-link to="/quienes-somos">
          <el-menu-item index="2"> Curaduría </el-menu-item>
        </router-link>
        <router-link to="/competencia">
          <el-menu-item index="3"> Competencia </el-menu-item>
        </router-link>
        <!-- <el-submenu index="3">
          <template slot="title"> Servicios </template>
          <el-submenu index="3-1">
            <template slot="title">
              <router-link to="/servicios?id=title&is=true">
                Licencias Urbanísticas
              </router-link>
            </template>
            <router-link to="/servicios?id=DefinicionClases&is=true">
              <el-menu-item index="3-1-1">
                Definición y clases de licencias urbanísticas
              </el-menu-item>
            </router-link>
            <router-link to="/servicios?id=LicenciasUrbanisticas&is=true">
              <el-menu-item index="3-1-2">
                Licencia de urbanización
              </el-menu-item>
            </router-link>
            <router-link to="/servicios?id=LicenciaParcelacion&is=true">
              <el-menu-item index="3-1-3">
                Licencia de parcelación
              </el-menu-item>
            </router-link>
            <router-link to="/servicios?id=LicenciaSubdivision&is=true">
              <el-menu-item index="3-1-4">
                Licencia de subdivision y sus modalidades
              </el-menu-item>
            </router-link>
            <router-link to="/servicios?id=LicenciaConstruccion&is=true">
              <el-menu-item index="3-1-5">
                Licencia de construcción y sus modalidades
              </el-menu-item>
            </router-link>
          </el-submenu>
          <el-submenu index="3-2">
            <template slot="title">
              <router-link to="/servicios?id=OtrasActuaciones&is=false">
                Otras actuaciones
              </router-link>
            </template>
            <router-link to="/servicios?id=Ajustes&is=false">
              <el-menu-item index="3-2-1">
                Ajuste de cota de areas
              </el-menu-item>
            </router-link>
            <router-link to="/servicios?id=Concepto1&is=false">
              <el-menu-item index="3-2-2">
                Concepto de norma Urbanística
              </el-menu-item>
            </router-link>
            <router-link to="/servicios?id=Concepto2&is=false">
              <el-menu-item index="3-2-3">
                Concepto de uso del suelo
              </el-menu-item>
            </router-link>
            <router-link to="/servicios?id=Concepto3&is=false">
              <el-menu-item index="3-2-4">
                Copia certificada de planos
              </el-menu-item>
            </router-link>
            <router-link to="/servicios?id=Concepto4&is=false">
              <el-menu-item index="3-2-5">
                Aprobación de los planos en propiedad horizontal
              </el-menu-item>
            </router-link>
            <router-link to="/servicios?id=Concepto5&is=false">
              <el-menu-item index="3-2-6">
                Aprobación de piscinas
              </el-menu-item>
            </router-link>
            <router-link to="/servicios?id=Concepto6&is=false">
              <el-menu-item index="3-2-6">
                Modificación de planos urbanísticos
              </el-menu-item>
            </router-link>
          </el-submenu>
        </el-submenu> -->
        <router-link to="/requisitos">
          <el-menu-item index="4"> Requisitos </el-menu-item>
        </router-link>
        <router-link to="/publicaciones">
          <el-menu-item index="7"> Publicaciones </el-menu-item>
        </router-link>
        <el-submenu index="5">
          <template slot="title"> Consultas </template>
          <router-link to="/consultas?page=actosAdmin">
            <!-- <div> -->
            <!--<el-menu-item index="5-1">
              Actos administrativos (licencias) - Pronto
            </el-menu-item>-->
            <!-- </div> -->
          </router-link>
          <router-link to="/consultas?page=consulteTramite">
            <!-- <div> -->
            <!--<el-menu-item index="5-2"
              >Consulte su trámite - Pronto
            </el-menu-item>-->
            <!-- </div> -->
          </router-link>
          <router-link to="/consultas?page=tarifas">
            <el-menu-item index="5-3">Tarifas</el-menu-item>
          </router-link>
          <router-link to="/consultas?page=Normatividad">
            <el-menu-item index="5-4">Normatividad</el-menu-item>
          </router-link>
          <router-link to="/consultas?page=cartografia">
            <el-menu-item index="5-5">Cartografía</el-menu-item>
          </router-link>
        </el-submenu>
        <router-link to="/contacto">
          <el-menu-item index="6"> Contáctenos </el-menu-item>
        </router-link>
      </el-menu>
    </div>
    <menuLateral class="flex md:hidden" />
  </nav>
</template>
<script>
export default {
  name: "KCHeader",
  components: {
    menuLateral: () => import("@/components/_menuLateral/_menuLateral.vue"),
  },
  created() {
    this.setMenu();
  },
  data() {
    return {
      activeIndex: "1",
    };
  },
  computed: {
    showMenu: {
      get() {
        return this.$store.state.showMenu;
      },
      set(newValue) {
        this.$store.commit("ACTIONS_MENU", newValue);
      },
    },
  },
  methods: {
    actionsMenu() {
      this.showMenu = true;
    },
    setMenu() {
      if (this.$route.path) {
        switch (this.$route.path) {
          case "/":
            this.activeIndex = "1";
            break;
          case "/quienes-somos":
            this.activeIndex = "2";
            break;
          case "/servicios":
            this.activeIndex = "3";
            break;
          case "/requisitos":
            this.activeIndex = "4";
            break;
          case "/consultas":
            this.activeIndex = "5";
            break;
          case "/contacto":
            this.activeIndex = "6";
            break;
        }
      }
    },
  },
  watch: {
    $route() {
      this.setMenu();
    },
  },
};
</script>

<style scoped>
.menuSetting >>> .el-menu-item:focus,
.el-menu-item:hover {
  background-color: transparent;
}
.menuSetting >>> .el-menu.el-menu--horizontal {
  border-bottom: solid 1px transparent;
}
.menuSetting >>> .el-dropdown-menu__item,
.menuSetting >>> .el-menu-item,
.menuSetting >>> .el-submenu__title {
  font-size: 16px;
  padding: 0 12px;
}
.menuSetting >>> .el-menu-item,
.menuSetting >>> .el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 40px;
  line-height: 40px;
  color: #999999 !important;
}
.menuSetting >>> .el-menu-item.is-active {
  border-bottom: 2px solid #fcdd07 !important;
  color: #999999 !important;
}
.menuSetting >>> .el-menu-item:focus {
  color: #999999 !important;
}
.menuSetting >>> .el-menu-item:hover {
  color: #fcdd07 !important;
}
.menuSetting
  >>> .el-menu--horizontal
  > .el-submenu.is-active
  .el-submenu__title {
  border-bottom: 2px solid #fcdd07 !important;
  color: #999999;
}
</style>
