import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "indexLanding",

    component: function () {
      return import("../views/index.vue");
    },
  },
  {
    path: "/quienes-somos",
    name: "quienes-somos",

    component: function () {
      return import("../views/KC_curaduria.vue");
    },
  },
  {
    path: "/requisitos",
    name: "requisitos",
    component: function () {
      return import("../views/KC_requirements.vue");
    },
  },
  {
    path: "/publicaciones",
    name: "publicaciones",
    component: function () {
      return import("../views/KC_publications.vue");
    },
  },
  {
    path: "/contacto",
    name: "contacto",
    component: function () {
      return import("../views/KC_contact.vue");
    },
  },
  {
    path: "/consultas",
    name: "consultas",
    component: function () {
      return import("../views/KC_consult.vue");
    },
  },
  {
    path: "/competencia",
    component: function () {
      return import("../components/services/index.vue");
    },
    children: [
      {
        path: "/",
        name: "competencia",
        component: function () {
          return import("../views/KC_services.vue");
        },
      },
      {
        path: "licenses",
        name: "licenses",
        component: function () {
          return import("../components/services/licenses.vue");
        },
      },
      {
        path: "otherservices",
        name: "otrosServicios",
        component: function () {
          return import("../components/services/otherServices.vue");
        },
      },
    ],
  },
  // {
  //   path: "*",
  //   component: function () {
  //     return import("../views/index.vue");
  //   },
  // },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
